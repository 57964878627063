<template>
  <v-app :style="colorVapp">
    <div>
      <onebox_toolbar
        :quicksearch="true"
        :parentfolder="parentfolder"
        @inputdata="filteredItems"
        :disableAutoFill="status_disautofill"
        @loadfile="loadfileandfolder()"
        @callparentfolder="sendParentfolder()"
        @callstorage="loadstorage()"
      ></onebox_toolbar>
      <v-content>
        <!-- <v-card outlined>
            <v-card-title style="padding-top: 5px; padding-bottom: 5px" color="grey">
              <v-btn text color="primary" @click.stop="openUploadfiles = true"><v-icon> note_add</v-icon>&nbsp; &nbsp; อัปโหลดไฟล์</v-btn>
              <v-btn text color="primary" @click.stop="openUploadfolder = true"><v-icon> create_new_folder</v-icon>&nbsp; &nbsp; สร้างโฟลเดอร์</v-btn>
            </v-card-title>
        </v-card>-->
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular
            indeterminate
            size="64"
            :color="color.theme"
          ></v-progress-circular>
          <span :style="colorProgress">&nbsp; &nbsp; loading</span>
        </v-overlay>
        <!-- <v-toolbar dense class="elevation-0">
          <v-avatar color="amber darken-3" size="35" class="mr-6">
            <v-icon dark size="27">star</v-icon>
          </v-avatar>
          <v-toolbar-title>
            <span
              style="color: #FF8F00; font-size: 18px; font-weight: 600;"
            >&nbsp; {{ $t('toolbar.mystarfile')}}</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
        
        </v-toolbar>-->
        <!-- <v-divider></v-divider> -->
        <v-card class="elevation-0">
          <!-- Header Page lg -->
          <v-list nav dense :color="color.BG" v-if="resolutionScreen >= 500">
            <v-list-item class="text-left">
              <v-list-item-avatar class="text-center pt-1">
                <v-avatar :color="color.theme" size="35">
                  <v-icon dark size="25">star</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="ml-n2 pt-2 pb-1">
                  <v-btn rounded text @click="loadfileandfolder">
                    <span :style="headerPage">{{
                      $t("toolbar.mystarfile")
                    }}</span>
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- Header Page xs -->
          <v-list nav :color="color.BG" class="mb-n2" v-else>
            <v-list-item class="text-left">
              <v-chip :style="headerChipPage" :color="$vuetify.theme.dark ? '#989898' : 'white'">
                <v-avatar class="text-center">
                  <v-icon :color="$vuetify.theme.dark ? 'white' : color.theme" dark size="20">mdi-star</v-icon>
                </v-avatar>
                <v-list-item-title class="ml-2">
                    <span :style="headerPageMobile">{{
                      $t("toolbar.mystarfile")
                    }}</span>
                </v-list-item-title>
              </v-chip>
            </v-list-item>
          </v-list>
          <!-- <div @contextmenu="show" style="margin-top: 10px"> -->
          <v-card class="elevation-0" id="thiscontainer_starredfiles" :color="color.BG">
            <v-card-text class="pa-0">
              <v-container fluid>
                <v-layout row wrap fill-height justify-center>
                  <v-flex
                    lg12
                    class="ma-2 ml-4 mr-4"
                    v-if="loaddataprogress === true"
                  >
                    <div class="text-center">
                      <v-progress-circular
                        :size="50"
                        :color="color.theme"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </v-flex>
                  <v-flex lg12 xs12 class="pa-4" v-else>
                    <v-layout
                      row
                      wrap
                      class="pl-3 pr-1"
                      v-if="resolutionScreen >= 500"
                    >
                      <v-flex lg2 xs4>
                        <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          dense
                          solo
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                          :item-color="color.theme"
                          :color="color.theme"
                          @change="loadfileandfolder"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 17px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select>
                      </v-flex>
                      <!-- <v-flex
                        lg4
                        offset-lg6
                        class="pl-3 pr-3 hidden-md-and-down"
                      >
                        <v-alert
                          dense
                          height="41"
                          v-model="clickSort"
                          :color="color.alertSort"
                          dark
                          v-show="clickSort"
                          style="margin-bottom: 0%;"
                        >
                          <div>
                            <v-icon
                              :color="color.alertText"
                              v-if="multiSort === true"
                              >mdi-chevron-up</v-icon
                            >
                            <v-icon
                              :color="color.alertText"
                              v-else-if="multiSort === false"
                              >mdi-chevron-down</v-icon
                            >
                            <span :style="colorSort" v-if="sortby === 'name'">{{
                              $t("Filter.name")
                            }}</span>
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'date'"
                              >{{ $t("Filter.date") }}</span
                            >
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'size'"
                              >{{ $t("Filter.size") }}</span
                            >
                            <span
                              :style="colorSort"
                              v-else-if="sortby === 'owner'"
                              >{{ $t("Filter.owner") }}</span
                            >
                            <v-btn
                              width="24"
                              height="24"
                              style="float: right;"
                              text
                              fab
                              x-small
                              :color="color.alertSort"
                              dark
                              @click="checkSort"
                            >
                              <v-icon :color="color.alertText"
                                >mdi-close-circle</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-alert>
                      </v-flex> -->
                      <v-spacer></v-spacer>
                      <v-flex>
                        <!-- Sort File ใหม่ -->
                        <v-list nav :color="color.BG" class="py-0">
                          <v-list-item>
                            <v-layout>
                              <v-flex class="text-right" lg12>
                                <v-menu offset-y rounded="lg">
                                  <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                      small
                                      rounded
                                      class="white--text"
                                      :color="color.theme"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon small>mdi-sort</v-icon>
                                      {{ $t("sorting.sort") }}:
                                      <div class="font-weight-bold">
                                        {{
                                          $t(
                                            sorting["sort"].find(
                                              (x) => x.id === sortAndDirection.sort_by
                                            ).text
                                          )
                                        }},
                                        {{
                                          $t(
                                            sorting["direction"].find(
                                              (x) => x.id === sortAndDirection.direction
                                            ).text
                                          )
                                        }}
                                      </div>
                                    </v-btn>
                                  </template>
                                  <v-list dense nav>
                                    <v-list-item-group
                                      v-model="sortAndDirection.sort_by"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting['sort']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="sortAndDirection.sort_by === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                    <v-divider class="mt-2" />
                                    <v-list-item-group
                                      class="mt-4"
                                      v-model="sortAndDirection.direction"
                                      active-class="border"
                                      :color="color.theme"
                                      mandatory
                                    >
                                      <template
                                        v-for="(item, i) in sorting['direction']"
                                      >
                                        <v-list-item
                                          :key="`item-${i}`"
                                          :value="item.id"
                                          @click="sortAndDirection.direction === item.id ? reloadSortByAndDirection() : ''"
                                        >
                                          <template>
                                            <v-list-item-content>
                                              <v-list-item-title>{{
                                                $t(item.text)
                                              }}</v-list-item-title>
                                            </v-list-item-content>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </v-list-item-group>
                                  </v-list>
                                </v-menu>
                              </v-flex>
                            </v-layout>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="resolutionScreen >= 500">
                      <v-flex xs4 lg4>
                        <div
                          v-if="statusmutipledownload === false"
                          class="pt-2 hidden-md-and-down"
                        >
                          <v-btn
                            @mouseover="show_multipledownload = true"
                            @mouseleave="show_multipledownload = false"
                            :color="color.theme"
                            rounded
                            :dark="color.darkTheme"
                            class="ma-1"
                            @click="(arrayfileidselect = []),
                              (statusmutipledownload = true),
                                (show_multipledownload = false)
                            "
                            fab
                            small
                          >
                            <v-icon>mdi-download-multiple</v-icon>&nbsp;
                            <!-- {{ $t('myinboxPage.multipledownload')}} -->
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_multipledownload"
                              :content="$t('balloon.menu_download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                        <div v-else class="pt-2">
                          <v-btn
                            @mouseover="show_download = true"
                            @mouseleave="show_download = false"
                            fab
                            small
                            color="primary"
                            class="ma-1"
                            @click="multipledownload()"
                          >
                            <v-icon>mdi-download-multiple</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-35px;"
                              :value="show_download"
                              :content="$t('balloon.download')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            @mouseover="show_clear = true"
                            @mouseleave="show_clear = false"
                            fab
                            small
                            color="error"
                            class="ma-1"
                            @click="
                              (statusmutipledownload = false),
                                clearmultipledownload(),
                                (show_clear = false)
                            "
                          >
                            <v-icon>clear</v-icon>
                            <v-badge
                              v-if="$t('default') === 'th'"
                              style="z-index:10000;bottom:-45px;left:-20px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                            <v-badge
                              v-else
                              style="z-index:10000;bottom:-45px;left:-30px;"
                              :value="show_clear"
                              :content="$t('balloon.clear')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </div>
                      </v-flex>
                      <v-flex xs8 lg8 class="text-right">
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          {{ rootFolderCount }}
                          {{ rootFolderCount > 1 ? $t("folders") : $t("folder") }}
                          <!-- {{countfolder}} {{countfolder > 1 ? $t('folders'): $t('folder')}} -->
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <!-- {{ count_files }}
                          {{ count_files > 1 ? $t("files") : $t("file") }} -->
                          {{rootFileCount}} {{rootFileCount > 1 ? $t('files'): $t('file')}}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <!-- หน้าจอ xs -->
                    <v-layout row wrap class="mt-n4 mr-n4" v-else>
                      <v-flex xs3 class="text-left">
                        <!-- <v-select
                          prepend-inner-icon="sort"
                          hide-details
                          outlined
                          solo
                          dense
                          color="red"
                          v-model="size"
                          :items="listsize"
                          class="text-center"
                          height="1"
                        >
                          <template v-slot:item="{ item }">
                            <div class="text-center">
                              <span style="font-size: 14px;">{{ item }}</span>
                            </div>
                          </template>
                          <template v-slot:selection="{ item }">
                            <div class="text-center">
                              <span style="font-size: 14px;">{{ item }}</span>
                            </div>
                          </template>
                        </v-select> -->
                        <v-menu offset-y>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn x-small rounded
                              :color="color.theme" 
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-2"
                            >
                              <v-icon class="mr-2">sort</v-icon><span class="font-weight-bold" :color="color.theme">{{listsizemenu.find(x => x.value === size).value}}</span>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="size"
                              :color="color.theme"
                            >
                              <template  v-for="(item, i) in listsizemenu">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.value"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="size === item.value ? listOnBold : listNoBold" >{{ item.value }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <!-- Sort By -->
                      <v-flex xs9 class="text-right ml-n4">
                        <v-menu offset-y rounded="lg">
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn x-small rounded
                              :color="color.theme" 
                              v-bind="attrs"
                              v-on="on"
                              text
                              style="font-size:16px; letter-spacing: 0px;"
                              class="ml-3"
                            >
                              <span>{{ $t("sorting.sort") }} : </span>
                              <span class="font-weight-bold" :color="color.theme"> {{ $t(sorting['sort'].find(x => x.id === sortAndDirection.sort_by).text) }} </span><v-icon size="20">{{ $t(sorting['direction'].find(x => x.id === sortAndDirection.direction).text) === $t('sorting.ascending') ? 'mdi-arrow-up' : 'mdi-arrow-down'}}</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense nav :style="roundListSort">
                            <v-list-item-group
                              v-model="sortAndDirection.sort_by"
                              active-class="border"
                              :color="color.theme"
                              mandatory
                            >
                              <template v-for="(item, i) in sorting['sort']">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.id"
                                  @click="sortAndDirection.sort_by === item.id ? reloadSortByAndDirection() : ''"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="sortAndDirection.sort_by === item.id ? listOnBold : listNoBold" ><v-icon size="17" class="mr-2">{{item.icon}}</v-icon>{{ $t(item.text) }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                            <v-divider class="mt-2" />
                            <v-list-item-group
                              class="mt-4"
                              v-model="sortAndDirection.direction"
                              active-class="border"
                              :color="color.theme"
                              mandatory
                            >
                              <template v-for="(item, i) in sorting['direction']">
                                <v-list-item
                                  :key="`item-${i}`"
                                  :value="item.id"
                                  @click="sortAndDirection.direction === item.id ? reloadSortByAndDirection() : ''"
                                >
                                  <template>
                                    <v-list-item-content>
                                      <v-list-item-title :style="sortAndDirection.direction === item.id ? listOnBold : listNoBold">{{ $t(item.text) }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </template>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-flex>
                      <v-flex xs12 class="text-right mt-3 ml-n4">
                        <!-- <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>folder</v-icon>
                          <span
                            >{{ count_folders }}
                            <span v-if="resolutionScreen >= 400">{{
                              count_folders > 1 ? $t("folders") : $t("folder")
                            }}</span></span
                          >
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          :color="color.theme"
                          :text-color="color.chipText"
                        >
                          <v-icon left>mdi-file</v-icon>
                          <span
                            >{{ count_files }}
                            <span v-if="resolutionScreen >= 400">{{
                              count_files > 1 ? $t("files") : $t("file")
                            }}</span></span
                          >
                        </v-chip> -->
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4 mr-2" color="#1876D1" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">folder</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >
                            <!-- {{ count_folders }}
                            <span v-if="resolutionScreen >= 400">{{
                              count_folders > 1 ? $t("folders") : $t("folder")
                            }}</span> -->
                            {{ rootFolderCount }}
                            <span v-if="resolutionScreen >= 400">{{
                              rootFolderCount > 1 ? $t("folders") : $t("folder")
                            }}</span>
                            </span
                          >
                        </v-chip>
                        <v-chip style="height: 32px; border-radius: 32px;" class="px-4" color="#5AB685" :text-color="color.chipText">
                          <v-icon center size="18" class="mr-2">mdi-file-document</v-icon>
                          <span style="font-weight: 700; font-size:16px;"
                            >
                            <!-- {{ count_files }}
                            <span v-if="resolutionScreen >= 400">{{
                              count_files > 1 ? $t("files") : $t("file")
                            }}</span> -->
                            {{ rootFileCount }}
                            <span v-if="resolutionScreen >= 400">{{
                              rootFileCount > 1 ? $t("files") : $t("file")
                            }}</span>
                            </span
                          >
                        </v-chip>
                      </v-flex>
                    </v-layout>
                    <br />
                    <v-card v-if="resolutionScreen < 500" class="mt-n2" :style="displayMobile">
                      <v-list
                        two-line
                        class="elevation-0 px-2"
                        v-if="rootfile.length !== 0"
                        :style="styleDisplayMobile"
                      >
                        <v-list-item
                          v-for="item in paginatedData"
                          :key="item.title"
                          @contextmenu="rightclickfileandfolder($event, item)"
                          :style="displayMobileForListItem"
                          @click="
                            gotodirectory(
                              item.file_id,
                              item.data_type,
                              item.account_sender,
                              item,
                              paginatedData
                            )
                          "
                        >
                          <v-list-item-action>
                            <v-badge
                              color="orange"
                              overlap
                              icon="link"
                              v-if="
                                item.file_status_sharelink === 'Y' ||
                                  item.file_status_sharelink === 'P'
                              "
                            >
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="lock"
                              v-else-if="item.editing_status === 'Y'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge
                              color="red"
                              overlap
                              icon="mdi-sync-circle"
                              v-else-if="item.editing_status === 'S'">
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                            <v-icon v-else large :color="item.file_icon[1]">{{
                              item.file_icon[0]
                            }}</v-icon>
                          </v-list-item-action>
                          <!-- @click="gotodirectory(item.file_id,item.file_type,item.account_sender,item ,paginatedData)" -->
                          <v-list-item-content>
                            <!-- v-text="item.file_name" -->
                            <v-list-item-title style="font-size: 14px;">
                            <v-icon dark small class="pr-1" color="error" v-if="item.status_lock_data === 'Y'">mdi-lock</v-icon>
                              {{ item.file_name }}
                          </v-list-item-title>
                            <v-list-item-subtitle
                              v-text="
                                item.data_type === 'folder'
                                  ? '-'
                                  : calculatesize(item.file_size)
                              "
                              style="font-size: 12px;"
                            ></v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'Y'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'">{{ item.last_update_name.name_th }} กำลังแก้ไข</v-chip>
                              <v-chip x-small color="primary" v-else>{{ item.last_update_name.name_eng }} editting</v-chip>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-show="item.editing_status === 'S'">
                              <v-chip x-small color="primary" v-if="$t('default') === 'th'"> กำลังซิงค์...</v-chip>
                              <v-chip x-small color="primary" v-else>Syncing...</v-chip>
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              icon
                              fab
                              small
                              @click.stop="rightclickfileandfolder($event, item)"
                            >
                              <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <!-- 
                      <v-divider inset></v-divider>-->
                      </v-list>
                      <v-card-text class="mt-4 mb-4" v-else>
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="'font-size: 16px; font-weight: 700; line-height: 24px; color:' + color.theme + ';'"
                          class="text-center mt-8"
                        > {{$t('tablefile.empty')}} </v-list-item-title>
                      </v-card-text>
                      <!-- <v-list v-else class="elevation-1">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-center"
                              v-text="$t('tablefile.empty')"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> -->
                    </v-card>

                    <div v-else>
                      <v-data-table
                        v-model="filemultipledownload"
                        :headers="headers"
                        :items="rootfile"
                        :no-data-text="$t('tablefile.empty')"
                        :single-select="false"
                        :search="search"
                        item-key="file_id"
                        :show-select="statusmutipledownload"
                        class="elevation-0"
                        :page.sync="page"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                        <!-- <template v-slot:top>
                          <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
                        </template>-->
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'name'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_owner`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'size'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_lastdtm`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr
                            :style="selectItemTable(props.item.file_id, 'table') + 'cursor: pointer;'"
                            @contextmenu="rightclickfileandfolder($event, props.item)"
                            @dblclick="
                              gotodirectory(
                                props.item.file_id,
                                props.item.data_type,
                                props.item.account_sender,
                                props.item,
                                rootfile
                              )
                            "
                            @click="setSelectitemtable(props.item)"
                          >
                            <!-- <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="props.item" />
                          </td>-->
                            <td
                              class="text-center"
                              v-show="statusmutipledownload"
                            >
                              <v-checkbox
                                v-model="filemultipledownload"
                                :value="props.item"
                              />
                            </td>
                            <td width="7%" class="text-center">
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  props.item.file_status_sharelink === 'Y' ||
                                    props.item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                              </v-badge>
                              <v-badge color="red" overlap icon="lock" v-else-if="props.item.editing_status === 'Y'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-badge color="red" overlap icon="mdi-sync-circle" v-else-if="props.item.editing_status === 'S'">
                                <v-icon large :color="props.item.file_icon[1]">{{ props.item.file_icon[0] }}</v-icon>
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="props.item.file_icon[1]"
                                >{{ props.item.file_icon[0] }}</v-icon
                              >
                            </td>
                            <td width="50%">
                              <div
                                style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;width:550px"
                              >
                              <div style="display: inline-block; margin-left: 5px;"
                                v-if="word_searched === false">
                                {{ props.item.file_name }}
                              </div>
                              <div v-else v-html="fn_hilightWord(props.item.file_name)" 
                              style="display: inline-block; margin-left: 5px;">
                              </div>
                              <div v-show="props.item.editing_status === 'Y'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >{{ props.item.last_update_name.name_th }} กำลังแก้ไข</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >{{ props.item.last_update_name.name_eng }} editting</v-chip
                                >
                              </div>
                              <div v-show="props.item.editing_status === 'S'">
                                <v-chip x-small color="primary" v-if="$t('default') === 'th'"
                                  >กำลังซิงค์...</v-chip
                                >
                                <v-chip x-small color="primary" v-else
                                  >Syncing...</v-chip
                                >
                              </div>
                              </div>
                            </td>
                            <td width="10%" class="text-center" v-if="$t('default') === 'th'">
                              {{ props.item.file_owner_th }}
                              <v-icon
                                small
                                color="orange"
                                v-if="
                                  props.item.account_sender === '' &&
                                    props.item.file_status_share === 'Y'
                                "
                                >group_add</v-icon
                              >
                              <!-- v-if=" props.item.account_sender === '' && props.item.file_status_share === 'Y' " -->
                            </td>
                            <td width="10%" class="text-center" v-else>
                              {{ props.item.file_owner_eng }}
                              <v-icon
                                small
                                color="orange"
                                v-if="
                                  props.item.account_sender === '' &&
                                    props.item.file_status_share === 'Y'
                                "
                                >group_add</v-icon
                              >
                              <!-- v-if=" props.item.account_sender === '' && props.item.file_status_share === 'Y' " -->
                            </td>
                            <td width="12%" class="text-center">
                              {{ formatdatetime(props.item.file_lastdtm) }}
                            </td>
                            <td width="10%" class="text-center">
                              {{
                                props.item.data_type === "folder"
                                  ? "-"
                                  : calculatesize(props.item.file_size)
                              }}
                            </td>
                            <td
                              width="10%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                            ></td>
                            <td width="10%" v-else class="text-center">
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="setPermissionFileStar(props.item, 'N')"
                                v-if="props.item.file_status === 'S'"
                                text
                              >
                                <v-icon color="amber">star</v-icon>
                              </v-btn>
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="setPermissionFileStar(props.item, 'S')"
                                v-else
                                text
                              >
                                <v-icon>star_border</v-icon>
                              </v-btn>
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click.stop="rightclickfileandfolder($event, props.item)"
                                outlined
                              >
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11>
                        <v-pagination
                          v-model="page"
                          :length="pageCount || 0"
                          :color="color.theme"
                          v-if="rootfile.length > 0"
                          @input="loadfileandfolder()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-container>
              <previewfile
                :show="opennewpreviewfile"
                :filedata_1="currentfile"
                :parentfolder="parentfolder"
                :arrayfile="arrayfile"
                :param_preview="param_preview"
                @closepreviewfile="opennewpreviewfile = false"
                @openpreviewfile="opennewpreviewfile = true"
                @reload="loadfileandfolder()"
                @status_createpass="fn_disable_autofill"
              ></previewfile>
              <!-- <newpreviewfile
            :parentfolder="parentfolder"
            :show="opennewpreviewfile"
            :filedata="currentfile"
            @closenewpreviewfile ="opennewpreviewfile = false"
            @opennewpreviewfile="opennewpreviewfile = true"
            ></newpreviewfile>-->
              <uploadfiles
                :show="openUploadfiles"
                :parentfolder="parentfolder"
                @close="loadfileandfolder(), (openUploadfiles = false)"
              ></uploadfiles>
              <uploadfolder
                :show="openUploadfolder"
                :parentfolder="parentfolder"
                @close="loadfileandfolder(), (openUploadfolder = false)"
              ></uploadfolder>
              <mainrightclick
                :show="showMenu"
                :AreaX="x"
                :AreaY="y"
                @close="showMenu = false"
              ></mainrightclick>
              <!-- <listfile @closemaincontextmenu="showMenu = false"></listfile> -->
              <currentfilerightclick
                :parentfolder="parentfolder"
                :show="showcurrentMenu"
                :AreaX="x"
                :AreaY="y"
                :arrayfile="rootfile"
                @callupdatestatusfile="setPermissionFileStar"
                @closecurrent="(showcurrentMenu = false), loadfileandfolder()"
                @closecurrentonly="showcurrentMenu = false"
                :file="currentfile"
                @reload="loadfileandfolder()"
                @status_createpass="fn_disable_autofill"
              ></currentfilerightclick>
            </v-card-text>
            <!-- @reload="loadfolder(),loadfoldershare()" -->
          </v-card>
        </v-card>
      </v-content>
      <div style="visibility:hidden;" id="dropzone">
        <div id="textnode">
          <v-icon size="200" dark>cloud_upload</v-icon>
          <h3>วางไฟล์เพื่อทำการอัปโหลด</h3>
        </div>
      </div>
      <!-- snackbardowload -->
      <div class="text-center ma-2">
        <v-snackbar v-model="snackbardowload" bottom>
          <v-list style="padding-top: 0px;" class="mr-n2">
            <v-toolbar
              style="border-radius: 10px 10px 0px 0px;"
              dense
              short
              flat
              fab
              color="#1e1e1e"
            >
              <span style="color: white;">
                {{ $t("myinboxPage.dowload.dowloading") }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                color="white"
                text
                @click="snackbardowload = false"
                fab
                x-small
                class="mr-n1"
              >
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item dense style="height:40px; width:400px;">
              <v-list-item-content>
                <v-list-item-title style="color:black">{{
                  datetime
                }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                  class="mr-n1"
                  :rotate="90"
                  :size="35"
                  :width="3"
                  :value="percentCompleted"
                  :color="$vuetify.theme.dark ? 'white' : color.theme"
                  :dark="'white'"
                  style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script>
//Import lib
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";

// Import Component
const uploadfolder = () => import("../components/upload/dialog-uploadfolder");
const uploadfiles = () => import("../components/upload/dialog-uploadfiles2");
const currentfilerightclick = () =>
  import("../components/contextmenu/currentfilerightclick");
const mainrightclick = () => import("../components/contextmenu/mainrightclick");
const previewfile = () => import("../components/optional/dialog-preview");
import onebox_toolbar from "../components/layout/layout-toolbar-new";

import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    uploadfiles,
    InfiniteLoading,
    onebox_toolbar,
    mainrightclick,
    VueFullScreenFileDrop,
    uploadfolder,
    currentfilerightclick,
    previewfile,
  },
  data: function() {
    return {
      status_disautofill: false,
      word_searched: false,
      param_preview:"",
      count_folder: 0,
      count_file: 0,
      show_multipledownload: false,
      show_download: false,
      show_clear: false,
      arrayfile: [],
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      countfolder: "",
      countfile: "",
      fileCount: "",
      filenomal: [],
      foldernomal: [],
      myDateDownload: new Date().toISOString().slice(0, 10),
      statusmutipledownload: false,
      filemultipledownload: [],
      search: "",
      singleSelect: false,
      page: 1,
      size: 50,
      listsize: [20, 50, 100],
      listsizemenu: [
        { value:20 },
        { value:50 },
        { value:100 }
      ],
      lists: [],
      opennewpreviewfile: false,
      loaddataprogress: true,
      parentfolder: "",
      loadingfile: false,
      openUploadfolder: false,
      openUploadfiles: false,
      showcurrentMenu: false,
      showMenu: false,
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
      x: 0,
      y: 0,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "#",
          align: "center",
          value: "file_type",
          width: "7%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "50%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "center",
          value: "file_owner",
          width: "10%",
          sortable: false,
        },
        {
          text: "tablefile.lastmodified",
          value: "file_lastdtm",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.option",
          value: "file_status",
          width: "5%",
          align: "center",
          sortable: false,
        },
      ],
      sortby: "",
      clickSort: false,
      multiSort: false,

      rootfile: [],
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      arrayfileidselect: [],
      sorting: {
        sort: [{
            id: 'name',
            text: 'sorting.name',
            icon: 'mdi-file-document'
          }, {
            id: 'cre_dtm',
            text: 'sorting.date_created',
            icon: 'mdi-calendar-multiselect'
          }, {
            id: 'last_dtm',
            text: 'sorting.date_modified',
            icon: 'mdi-timer'
          }, {
            id: 'type',
            text: 'sorting.type',
            icon: 'mdi-text'
          },{
            id: 'size_file',
            text: 'sorting.size_file',
            icon: 'mdi-clipboard'
          }
        ],
        direction: [{
            id: 'ASC',
            text: 'sorting.ascending'
          }, {
            id: 'DESC',
            text: 'sorting.descending'
          }
        ]
      },
      sortAndDirection: {
        sort_by: localStorage.getItem('sort_by') !== null ? localStorage.getItem('sort_by') : "cre_dtm",
        direction: localStorage.getItem('direction_by') !== null ? localStorage.getItem('direction_by') : "ASC",
      },
      sortBy: [],
      sortDesc: []
      // permission_currentfolder: {
      //   download: "False",
      //   edit: "False",
      //   view_only: "True"
      // },
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "feature_onlyoffice"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataAccountId",
    ]),
    countfolders() {
      let countfolder = this.foldernomal.length;
      return countfolder;
    },
    countfiles() {
      let countfile = this.filenomal.length;
      return countfile;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        // let l = this.rootfile.length;
        let l = this.fileCount;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    // filters: {
    //   subStr: function(string) {
    //     if(string == null || string == '' || string == undefined){
    //       return string
    //     }else{
    //       if (string.length > 30) return string.substring(0, 30) + "...";
    //     else return string;
    //     }
    //   }
    // },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.rootfile.slice(start, start + this.size);
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" + this.color.theme + ";" + "font-size: 24px; font-weight: 600;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return "color:" + this.color.theme + ";" + "font-size: 16px; font-weight: 700; line-height: 24px;";
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;"
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark){
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;"
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);"
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;"
      } else {
        return "background: #FFFFFF;"
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;"
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;"
      }
    },
    rootFolderCount() {
      return this.rootfile.filter(item => item.file_type === "folder").length;
    },
    rootFileCount() {
      return this.rootfile.filter(item => item.file_type !== "folder").length;
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  methods: {
    //ดึงข้อมูลเจ้าของไฟล์ที่ไม่ใช่ของฉัน
    async get_name_owner(listrootfile){
      console.log("listrootfile",listrootfile);
      let payload = {
        data_id : listrootfile.file_id ,
        data_type : listrootfile.file_type === 'folder' ? 'folder' : 'file',
        type_search : "creator"
      }
      console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v1/get/data/creator_name", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng
            listrootfile.file_owner_th = response.data.name.name_th
          } 
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title:"ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    reloadSortByAndDirection() {
      this.loadstorage();
      this.loadfileandfolder();
    },
    fn_disable_autofill (value) {
      console.log("Stared ", value)
      this.status_disautofill = value
    },
    fn_hilightWord (text) {
      var regex = new RegExp(this.search, "gi")
      var response = text.replaceAll(regex, function(str) {
          return "<mark>" + str + "</mark>"
      })
      return response
      //return text.replaceAll(new RegExp(this.data_search.name,"gi"), `<mark>${this.data_search.name}</mark>`);
    },
    checkSort() {
      // this.loadfolder();
      this.rootfile = this.buff;
      this.clickSort = false;
      this.multiSort = false;
    },
    // sortFile(parameter) {
    //   this.folderSort = [];
    //   this.fileSort = [];
    //   this.sortdata = [];
    //   this.rootfile = [];
    //   let i, j;
    //   for (i = 0; i < parameter.length; i++) {
    //     if (parameter[i].file_type === "folder") {
    //       this.folderSort.push(parameter[i]);
    //     } else this.fileSort.push(parameter[i]);
    //   }

    //   if (this.sortby === "name") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_name.localeCompare(b.file_name);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_name.localeCompare(a.file_name);
    //       });
    //     }
    //   } else if (this.sortby === "date") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_lastdtm.localeCompare(b.file_lastdtm);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_lastdtm.localeCompare(a.file_lastdtm);
    //       });
    //     }
    //   } else if (this.sortby === "size") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_size - a.file_size;
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_size - b.file_size;
    //       });
    //     }
    //   } else if (this.sortby === "owner") {
    //     if (this.multiSort === true) {
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_eng.localeCompare(b.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return a.file_owner_th.localeCompare(b.file_owner_th);
    //       });
    //     } else {
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.folderSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_eng.localeCompare(a.file_owner_eng);
    //       });
    //       this.fileSort.sort(function(a, b) {
    //         return b.file_owner_th.localeCompare(a.file_owner_th);
    //       });
    //     }
    //   }
    //   this.sortdata.push(this.folderSort);
    //   this.sortdata.push(this.fileSort);
    //   for (i = 0; i < this.sortdata.length; i++) {
    //     for (j = 0; j < this.sortdata[i].length; j++) {
    //       this.rootfile.push(this.sortdata[i][j]);
    //     }
    //   }
    //   return this.rootfile;
    // },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    async multipledownload() {
      this.percentCompleted = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        // this.$store.dispatch("show_loading", true);
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i].data_type === "folder") {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: this.filemultipledownload[i]["file_type"],
            });
          } else {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: "file",
            });
          }
        }
        console.log("multi", filedata);

        let payload = {
          list: filedata,
          account_id: this.dataAccountId,
        };
        this.snackbardowload = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
              "/api/download_multiples_all",
            payload,
            {
              onDownloadProgress: (progressEvent) => {
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                // console.log(progresspercent);
                if (progresspercent >= 95) {
                  this.percentCompleted = 95;
                } else {
                  this.percentCompleted = progresspercent;
                }
              },
              withCredentials: false,
              responseType: "arraybuffer",
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            this.statusmutipledownload = false;
            console.log(response);
            // if (response.statusText === "OK") {
            if (response.status === 200) {
               if (
                parseInt(response.headers["content-length"]) < 700 &&
                (response.headers["content-type"]) === "application/json"
              ) {
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percentCompleted = 100;
                const blob = new Blob([response.data]);
                const content = response.headers["content-type"];
                saveAs(blob, this.datetime + ".zip");
              }
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("toast.cannotconnectonebox"),
              });
            }
            //     const url = window.URL.createObjectURL(new Blob([response.data]));
            //     const link = document.createElement("a");
            //     link.href = url;
            //     link.setAttribute("download",datetime+".zip");
            //     document.body.appendChild(link);
            //     link.click();
            // const blob = new Blob([response.data]);
            // const content = response.headers["content-type"];
            // saveAs(blob, this.datetime + ".zip");
            this.clearmultipledownload();
          })
          .catch((error) => {
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
      }
    },
    // multipledownload() {
    //   var d = new Date();
    //   let datetime =d.toISOString().slice(0,10)+" "+"at"+" "+this.zeroPadding(d.getHours(),2)+"-"+this.zeroPadding(d.getMinutes(),2)+"-"+this.zeroPadding(d.getSeconds(),2);
    //   if (this.filemultipledownload.length < 1) {
    //     Toast.fire({
    //       type: "warning",
    //       title: this.$t("myinboxPage.msg.nofile")
    //     });
    //   } else {
    //     this.$store.dispatch("show_loading", true);
    //     let filedata = [];
    //     for (let i = 0; i < this.filemultipledownload.length; i++) {
    //       filedata.push(this.filemultipledownload[i]["file_id"]);
    //     }

    //     let payload = {
    //       list_file: filedata,
    //       account_id: this.dataAccountId
    //     };

    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
    //           "/api/multiple_downloads_file",
    //         payload,
    //         {
    //           withCredentials: false,
    //           responseType: "blob"
    //         }
    //       )
    //       .then(response => {
    //         this.statusmutipledownload = false;
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download",datetime+".zip");
    //         document.body.appendChild(link);
    //         link.click();
    //         this.$store.dispatch("show_loading", false);
    //         this.clearmultipledownload();
    //       })
    //       .catch(error => {
    //         this.$store.dispatch("show_loading", false);
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("myinboxPage.msg.apierror")
    //         });
    //         console.log(error);
    //       });
    //   }
    // },
    filteredItems(search) {
      this.search = search;
      if(this.search.length > 0){
        this.word_searched = true
      } else {
        this.word_searched = false
      }
    },
    loadstorage() {
      this.$store
        .dispatch("check_storage", this.dataAccountId)
        .then((res) => {});
    },
    sendParentfolder() {
      // if (this.$route.params.id === undefined) {
      this.parentfolder = this.account_active["directory_id"];
      // } else {
      //   this.parentfolder = this.$route.params.id;
      // }
    },

    async setPermissionFileStar(parameter, status) {
      console.log("parameter",parameter);
      //if(this.dataAccountId === parameter.account_sender ){
      // if (parameter.file_status_share !== "Y") {
      let auth = await gbfGenerate.generateToken();
      console.log("sender", parameter.account_sender);
      console.log("id", parameter);
      // if(parameter.file_owner_eng === 'Me' || parameter.file_owner_th === 'ฉัน' ||parameter.file_owner_eng === '-'||parameter.file_owner_th === '-'){
      if (parameter.account_sender === "") {
        let api;
        let payload;
        let msgres;
        if (status === "S") {
          payload = {
            starred_id: [parameter.starred_id],
            data_id: [parameter.file_id],
            account_id : this.dataAccountId,
            // account_id: parameter.account_sender === undefined || parameter.account_sender === null ? "" : parameter.account_sender ,
            process: "insert",
          };
          api = "/api/multiple_star";
        } else {
          payload = {
            data_id: [parameter.file_id],
            starred_id: [parameter.starred_id],
            data_id: [parameter.file_id],
            account_id : this.dataAccountId,
            // account_id: parameter.account_sender === undefined || parameter.account_sender === null ? "" : parameter.account_sender ,
            process: "delete",
          };
          api = "/api/multiple_star";
        }
        // if (parameter.file_type === "folder") {
        //   payload = {
        //     account_id: this.dataAccountId,
        //     folder_id: parameter.file_id,
        //     folder_name: "",
        //     status_folder: status,
        //     move_to_id: "",
        //     user_id: this.dataUsername
        //   };
        //   api = "/api/v2/update_folder_id";
        // } else {
        //   payload = {
        //     account_id: this.dataAccountId,
        //     user_id: this.dataUsername,
        //     file_id: parameter.file_id,
        //     filename: "",
        //     folder_id: "",
        //     status_file: status
        //   };
        //   api = "/api/v2/update_file_id";
        // }
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log("file11111", response);
            if (response.data.status === "OK") {
              if (status === "S") {
                // msgres = "ติดดาว " + parameter.file_name + " สำเร็จ";
                msgres =
                  this.$t("toast.text.starred") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              } else if (status === "T") {
                // msgres = "นำ " + parameter.file_name + " ออกสำเร็จ";
                msgres =
                  this.$t("toast.text.remove") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              } else {
                this.rootfile = this.rootfile.filter(item => {
                  return item.file_id !== parameter.file_id;
                })
                // msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
                msgres =
                  this.$t("toast.text.canclestart") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              }

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closecurrent')
              // this.loadfolder();
              // this.loadfileandfolder();
              // this.loadfoldershare();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
              title:
                this.$t("toast.text.cannotstar") +
                parameter.file_name +
                this.$t("toast.text.textth"),
            });
          });
      } else {
        let api;
        let payload;
        let msgres;
        if (status === "S") {
          payload = {
            starred_id: [parameter.starred_id],
            data_id: [parameter.file_id],
            // account_id: parameter.account_sender,
            account_id : this.dataAccountId,
            process: "insert",
          };
          api = "/api/multiple_star";
        } else {
          payload = {
            data_id: [parameter.file_id],
            starred_id: [parameter.starred_id],
            // account_id: parameter.account_sender,
            account_id : this.dataAccountId,
            process: "delete",
          };
          api = "/api/multiple_star";
        }
        // if (parameter.file_type === "folder") {
        //   payload = {
        //     id: parameter.file_id,
        //     account_id: this.dataAccountId, //ต้องเปลี่ยนเป็น this.dataAccountId
        //     status_folder: status
        //   };
        //   api = "/api/update_share_statusfolder";
        // } else {
        //   payload = {
        //     id: parameter.file_id,
        //     account_id: this.dataAccountId, //ต้องเปลี่ยนเป็น this.dataAccountId
        //     status_file: status
        //   };
        //   api = "/api/update_share_statusfile";
        // }
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              if (status === "S") {
                // msgres = "ติดดาว " + parameter.file_name + " สำเร็จ";
                msgres =
                  this.$t("toast.text.starred") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              } else if (status === "T") {
                // msgres = "นำ " + parameter.file_name + " ออกสำเร็จ";
                msgres =
                  this.$t("toast.text.remove") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              } else {
                this.rootfile = this.rootfile.filter(item => {
                  return item.file_id !== parameter.file_id;
                })
                //  msgres = "ยกเลิกติดดาว " + parameter.file_name + " สำเร็จ";
                msgres =
                  this.$t("toast.text.canclestart") +
                  parameter.file_name +
                  this.$t("toast.text.success");
              }

              Toast.fire({
                icon: "success",
                title: msgres,
              });
              // this.$emit('closecurrent')
              // this.loadfolder();
              // this.loadfileandfolder();
              // this.loadfoldershare();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          })
          .catch((error) => {
            console.log(error);
            Toast.fire({
              icon: "error",
              // title: "ไม่สามารถติดดาว " + parameter.file_name + " ได้"
              title:
                this.$t("toast.text.cannotstar") +
                parameter.file_name +
                this.$t("toast.text.textth"),
            });
          });
      }
    },

    rightclickfileandfolder(e, file) {
      // เมื่อคลิกจุดสามจุด ให้โฟกัสรายการที่เลือก
      if (!this.arrayfileidselect.includes(file.file_id)) {
        this.arrayfileidselect = [file.file_id]
      }
      // console.log(e);
      e.preventDefault();
      this.sendParentfolder();
      this.showcurrentMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.showMenu = false;
      this.$nextTick(() => {
        // console.log(file);
        this.currentfile = file;
        this.showcurrentMenu = true;
      });
    },

    gotodirectory(folderid, type, account_sender, item, file) {
      console.log("item", item);
      console.log("type+", type);
      console.log("item.account_sender ", item.account_sender);
      if (type === "folder") {
        if (
          item.account_sender === "" ||
          item.account_sender === undefined ||
          item.account_sender === null
        ) {
          this.$router.replace({ path: "/directory/" + folderid });
          // this.loadfolder();
          this.loadfileandfolder();
        } else {
          this.$router.replace({ path: "/directorysharefile/" + folderid });
          // this.loadfolder();
          this.loadfileandfolder();
        }
      } else {
        this.newpreviewfile(item, file);
      }
    },

    show(e) {
      // console.log(e);
      e.preventDefault();
      this.sendParentfolder();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        if (this.showcurrentMenu === true) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      });
    },

    newpreviewfile(item, file) {
      console.log("ooooooo", file);
      var file_type_ = item.file_name.split('.');
      console.log("file_type+",file_type_);
      if (item.data_type !== "folder") {
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
          // console.log("checkinet_jv",checkinet_jv);
          // if(checkinet_jv === -1){
        // let checkinet_jv = VueCookies.get("feature_onlyoffice")
        // if(checkinet_jv === 'false'){
        let checkinet_jv = this.feature_onlyoffice;
          if(checkinet_jv === false){
            this.param_preview = "preview";
            this.opennewpreviewfile = true;
          }else{
            if(
              // (['04', '05', '06'].includes(item.file_permission_2)) &&
              (file_type_[file_type_.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file_type_[file_type_.length - 1].toLowerCase()) || 
              file_type_[file_type_.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(file_type_[file_type_.length - 1].toLowerCase()))
            ){
              this.param_preview = "editfileonline";
            }else{
              this.param_preview = "preview";
            }
          }
        console.log("เข้า");
        if(item.editing_status === 'S'){
          this.openpreviewfile = false;
          Toast.fire({
            icon: "warning",
            title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
          });
        }else if(item.editing_status === 'Y'){
          this.opennewpreviewfile = true;
          // if(item.last_update_name.name_th === 'ฉัน'){
          //   this.opennewpreviewfile = true;
          // }else{
          //   this.openpreviewfile = false;
          //   Toast.fire({
          //     icon: "warning",
          //     title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
          //   });
          // }
        }else{
          this.opennewpreviewfile = true;
        }
        this.currentfile = item;
        // this.opennewpreviewfile = true;
        this.arrayfile = file;
        console.log("arrayfile newpreviewfile ", this.arrayfile);
      }
    },

    onDrop(files) {
      console.log(files);
    },

    // โหลด file & folder new
    async loadfileandfolder() {
      this.loaddataprogress = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      // CLEAR PARAMETER
      // this.newrootfile = [];
      this.fileCount = 0;
      this.count_files = 0;
      this.count_folders = 0;
      this.buff = [];
      this.rootfile = [];

      // Personal
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        // folder_id: this.account_active["directory_id"],
        // folder_id: "",
        // status_file: "S",
        // status_folder: "S",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sortAndDirection.sort_by,
        direction: this.sortAndDirection.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder_starred",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            console.log("Response new API(Star) ", response.data.result);
            // this.permission_currentfolder =
            //   response.data.your_folder[0]["permission_account"];
            // ใช้ของเก่าก่อน
            this.fileCount = response.data.count;
            this.countfile = response.data.count_file;
            this.countfolder = response.data.count_folder;

            // File and Folder
            for (let i = 0; i < response.data.result.length; i++) {
              let dataFileAndFolder = {};
              let typefile
              let type
              if(response.data.result[i].data_type === 'folder'){
                typefile = response.data.result[i].data_type;
                type = response.data.result[i].data_type;
              }else{
                let type_file = response.data.result[i].name.split(".");
                typefile =  type_file[type_file.length - 1]
                type = response.data.result[i].data_type;
              }
              dataFileAndFolder["account_reciever"] = "";
              // dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_sender"] =
                response.data.result[i].account_id_sender;
              dataFileAndFolder["account_id"] =
                response.data.result[i].account_id;
              dataFileAndFolder["business_id"] =
                response.data.result[i].business_id;
              dataFileAndFolder["file_name"] =
                response.data.result[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.result[i]["name_eng"];
              dataFileAndFolder["file_owner_th"] =
                response.data.result[i]["name_th"];
              dataFileAndFolder["file_type"] =
                response.data.result[i].type;
              dataFileAndFolder["data_type"] =
                response.data.result[i].data_type;
              dataFileAndFolder["expiration_time"] =
                response.data.result[i]["expiration_time"];
              dataFileAndFolder["warning_expiration_time"] =
                response.data.result[i]["warning_expiration_time"];
              dataFileAndFolder["folder_id"] =
                response.data.result[i].folder_id;
              dataFileAndFolder["full_path"] =
                response.data.result[i].full_path;
              dataFileAndFolder["full_path_id"] =
                response.data.result[i].full_path_id;
              dataFileAndFolder["file_id"] = response.data.result[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.result[i].last_dtm;
              dataFileAndFolder["file_linkshare"] =
                response.data.result[i].link;
              dataFileAndFolder["parent_folder_id"] =
                response.data.result[i].parent_folder_id;
              dataFileAndFolder["file_permission"] =
                response.data.result[i].permission_account;
              dataFileAndFolder["file_permission_2"] =
                response.data.result[i].permission_account_v2;
              dataFileAndFolder["permission_department_setting"] =
                response.data.result[i].permission_department_setting;
              dataFileAndFolder["permission_role_setting"] = 
                response.data.result[i].permission_role_setting;
              dataFileAndFolder["permission_business"] =
                response.data.result[i].permission_business;
              dataFileAndFolder["permission_business_setting"] =
                response.data.result[i].permission_business_setting;
              dataFileAndFolder["permission_department"] =
                response.data.result[i].permission_department;
              dataFileAndFolder["permission_business"] =
                response.data.result[i].permission_business;
              dataFileAndFolder["permission_setting"] =
                response.data.result[i].permission_setting;
              dataFileAndFolder["priority"] = response.data.result[i].priority;
              dataFileAndFolder["status_expired"] =
                response.data.result[i]["status_expired"];
              dataFileAndFolder["file_status_share"] =
                response.data.result[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.result[i].status_share_link;
              dataFileAndFolder["file_status"] =
                response.data.result[i].status;
              dataFileAndFolder["system"] = response.data.result[i].system;
              dataFileAndFolder["update_by"] = response.data.result[i].update_by;
              dataFileAndFolder["user_id"] = response.data.result[i].user_id;
              dataFileAndFolder["file_createdtm"] =
                response.data.result[i].cre_dtm;
              dataFileAndFolder["relation_id"] =
                response.data.result[i].relation_id;
              dataFileAndFolder["relation_name"] =
                response.data.result[i].relation_name;
              dataFileAndFolder["relation_status"] =
                response.data.result[i].relation_status;
              dataFileAndFolder["sender_name"] =
                response.data.result[i].sender_name;
              dataFileAndFolder["sender_name_en"] =
                response.data.result[i].sender_name_en;
              dataFileAndFolder["file_icon"] = this.seticon_New(
                type,
                typefile
              );
              // dataFileAndFolder["file_icon"] = this.seticon_New(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder["file_size"] =
                response.data.result[i].size_bytes;
              dataFileAndFolder["share_status"] =
                response.data.result[i].share_status;
              dataFileAndFolder["status_expiration_time"] =
                response.data.result[i].status_expiration_time;
              dataFileAndFolder["status_expired"] =
                response.data.result[i].status_expired;
              dataFileAndFolder["status_lock_data"] =
                response.data.result[i].status_lock_data;
              dataFileAndFolder["status_updating"] =
                response.data.result[i].status_updating;
              dataFileAndFolder["version_tag"] =
                response.data.result[i].version_tag;
              dataFileAndFolder["editing_status"] =
                response.data.result[i].editing_status;
              dataFileAndFolder["starred_id"] =
                response.data.result[i].starred_id;
              dataFileAndFolder["last_update_name"] =
                response.data.result[i].last_update_name;
                

              if (dataFileAndFolder["file_type"] === "folder") {
                this.count_folders = this.count_folders + 1;
              } else {
                this.count_files = this.count_files + 1;
              }
              console.log(
                this.count_files,
                this.count_folders,
                dataFileAndFolder["file_type"]
              );
              this.rootfile.push(dataFileAndFolder);
            }
            console.log("Newrootfile(Star)", this.rootfile);
            this.loaddataprogress = false;

            for(let y = 0; y < this.rootfile.length; y++){
              if(this.rootfile[y].file_owner_th === '' || this.rootfile[y].file_owner_eng === ''){
                await this.get_name_owner(this.rootfile[y])
              }
            }
          }
        })
        .catch((error) => {
          console.log("New Loaddata Error:", error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            // title: this.$t("toast.cannotconnectonebox"),
            title:"ไม่สามารถเชื่อมต่อไป"
          });
        });
    },
    // ตั้ง icon file/folder
    seticonNew(type, parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      let dataicon;
      if (type == "folder") {
        dataicon = [
          "folder",
          // "blue"
          "#FDD361",
        ];
      } else {
        if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
          dataicon = ["mdi-file-excel-outline", "green"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint-outline", "orange"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word-outline", "primary"];
        } else if (parameter === "html") {
          dataicon = ["mdi-language-html5", "#FF603F"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box-outline", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf-outline", "red"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code-outline", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text-outline", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png"
        ) {
          dataicon = ["mdi-file-image-outline", "blue-grey"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video-outline", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link-outline", "pink"];
        } else {
          dataicon = ["mdi-file-outline", "black"];
        }
      }

      return dataicon;
    },

    // ตั้ง icon file/folder
    seticon_New(type, parameter) {
      console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      let dataicon;
      if (type == "folder") {
        // dataicon = [
        //   "folder",
        //   // "blue"
        //   "#FDD361",
        // ];
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        if (parameter === "xlsx" || parameter === "xls" || parameter === "xlsb") {
          // dataicon = ["mdi-file-excel", "green"];
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          // dataicon = ["mdi-file-powerpoint", "orange"];
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          // dataicon = ["mdi-file-word", "primary"];
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "html") {
          // dataicon = ["mdi-language-html5", "orange"];
        dataicon = ["mdi-language-html5", "#FF603F"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          // dataicon = ["mdi-file-pdf", "red"];
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png" ||
          parameter === "gif" ||
          parameter === "bmp"
        ) {
          // dataicon = ["mdi-file-image", "blue-grey"];
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      }

      return dataicon;
    },
    async loadfolder() {
      // console.log($state);
      // alert("Old Function");
      this.foldernomal = [];
      this.rootfile = [];
      this.buff = [];
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: "",
        status_folder: "S",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/v2/search_folder_byid",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("resfoldernomal", response);
            var i;
            for (i = 0; i < response.data.folder.length; i++) {
              let datafolder = {};
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = "";
              datafolder["file_id"] = response.data.folder[i]["folder_id"];
              datafolder["file_name"] = response.data.folder[i]["folder_name"];
              datafolder["file_size"] = "-";
              datafolder["file_status"] =
                response.data.folder[i]["folder_status"];
              datafolder["file_owner_eng"] =
                response.data.folder[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.folder[i]["name_th"];
              datafolder["file_linkshare"] = "-";
              datafolder["file_status_share"] =
                response.data.folder[i]["status_share"];
              datafolder["file_status_sharelink"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_type"] = "folder";
              datafolder["file_date"] = "";
              datafolder["file_lastdtm"] = "";
              datafolder["file_department"] = "";
              datafolder["file_icon"] = ["folder", "#FDD361"];
              datafolder["permission_account"] =
                response.data.folder[i].permission_account;
              datafolder["file_permission_2"] =
                response.data.result[i].permission_account_v2;
              datafolder["permission_department_setting"] =
                response.data.folder[i].permission_department_setting;
              datafolder["permission_setting"] =
                response.data.folder[i].permission_setting;
              datafolder["status_expired"] =
                response.data.folder[i]["status_expired"];
              datafolder["expiration_time"] =
                response.data.folder[i]["expiration_time"];
              datafolder["warning_expiration_time"] =
                response.data.folder[i]["warning_expiration_time"];

              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.foldernomal.push(datafolder);
            }
            console.log("Data =>", this.rootfile);
          }
          if (this.clickSort === true) {
            this.rootfile.push(this.sortFile(this.rootfile));
          }

          // var payloadSh = {
          //   account_id: this.dataAccountId
          // };
          // this.axios
          //   .post(
          //     process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          //       "/api/get_sharefolder",
          //     payloadSh
          //   )
          //   .then(response => {
          //     console.log("resfoldershare",response);
          //     if (response.data.status === "OK") {
          //       console.log(response.data.status);
          //       // this.rootfile = response.data.folder
          //       // if (response.data.folder.length) {
          //       //   this.page += 1;
          //       //   this.rootfile.push(...response.data.folder);
          //       //   $state.loaded();
          //       // } else {
          //       //   $state.complete();
          //       // }
          //       var i;
          //       for (i = 0; i < response.data.message.length; i++) {
          //         if (response.data.message[i]["status_folder"] === "S") {
          //           let datafolder = {};
          //           datafolder["account_reciever"] =
          //             response.data.message[i]["account_id_reciever"];
          //           datafolder["account_sender"] =
          //             response.data.message[i]["account_id_sender"];
          //           datafolder["file_id"] = response.data.message[i]["id"];
          //           datafolder["file_size"] = "-";
          //           datafolder["file_status"] =
          //             response.data.message[i]["status_folder"];
          //           datafolder["file_name"] =
          //             response.data.message[i]["folder_name"];
          //           datafolder["file_owner_eng"] = "";
          //           datafolder["file_owner_th"] = "";
          //           datafolder["file_linkshare"] = "";
          //           datafolder["file_date"] =
          //             response.data.message[i]["share_dtm"];
          //           datafolder["file_lastdtm"] ="";
          //           datafolder["file_type"] = "folder";
          //           datafolder["file_status_share"] = "Y";
          //           datafolder["file_permission"] =
          //             response.data.message[i]["status"];
          //           datafolder["file_status_sharelink"] = "";
          //           datafolder["file_department"] = "";
          //           datafolder["file_icon"] = ["folder", "blue"];
          //           this.rootfile.push(datafolder);
          //           this.buff.push(datafolder);
          //           this.foldernomal.push(datafolder);
          //           // console.log(this.rootfile);
          //         }
          //       }
          // if(this.clickSort === true){
          //   this.rootfile.push(this.sortFile(this.rootfile))
          // }
          // }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
      this.loadfiles("share", "S");
      // }

      // .catch(error => {
      //   console.log(error.response);
      //   this.loaddataprogress = false;
      //   Toast.fire({
      //     icon: "error",
      //     title: "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
      //   });
      // });
    },

    async loadfoldershare($state) {
      this.foldernomal = [];
      this.rootfile = [];
      this.buff = [];
      var payloadSh = {
        account_id: this.dataAccountId,
        status: "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        // .post(
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_sharefolder",
        //   payloadSh
        // )
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FOLDER +
            "/api/taxid/get_sharefolder",
          payloadSh,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("resfoldershare", response);
            // console.log(response.data.status);
            // this.rootfile = response.data.folder
            // if (response.data.folder.length) {
            //   this.page += 1;
            //   this.rootfile.push(...response.data.folder);
            //   $state.loaded();
            // } else {
            //   $state.complete();
            // }
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              console.log(response.data.data[i]);
              if (response.data.data[i]["status_folder"] == "S") {
                let datafolder = {};
                datafolder["account_reciever"] =
                  response.data.data[i]["account_id_reciever"];
                datafolder["account_sender"] =
                  response.data.data[i]["account_id_sender"];
                datafolder["file_id"] = response.data.data[i]["id"];
                datafolder["file_size"] = "-";
                datafolder["file_status"] =
                  response.data.data[i]["status_folder"];
                datafolder["file_name"] = response.data.data[i]["folder_name"];
                datafolder["file_owner_eng"] = response.data.data[i].name_eng;
                datafolder["file_owner_th"] = response.data.data[i].name_th;
                datafolder["file_linkshare"] = "";
                datafolder["file_date"] = response.data.data[i]["share_dtm"];
                datafolder["file_lastdtm"] = "";
                datafolder["file_type"] = "folder";
                datafolder["file_status_share"] = "Y";
                datafolder["file_permission"] = response.data.data[i]["status"];
                datafolder["file_status_sharelink"] = "";
                datafolder["file_department"] = "";
                datafolder["file_icon"] = ["folder", "#FDD361"];
                datafolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                datafolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                datafolder["warning_expiration_time"] =
                  response.data.data[i]["warning_expiration_time"];
                datafolder["permission_account"] =
                  response.data.data[i]["permission_account"];
                datafolder["file_permission_2"] =
                response.data.result[i]["permission_account_v2"];
                datafolder["permission_department_setting"] =
                  response.data.data[i]["permission_department_setting"];
                datafolder["permission_setting"] =
                  response.data.data[i]["permission_setting"];
                this.rootfile.push(datafolder);
                this.buff.push(datafolder);
                this.foldernomal.push(datafolder);
                // console.log(this.rootfile);
              }
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเชื่อมต่อไปที่ ONE-BOX ได้ในขณะนี้"
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
      this.loadfilesshare("", "S");
    },

    async loadfiles(folder, status) {
      console.log("sentfolder", folder);
      console.log(status);

      this.filenomal = [];
      var payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: folder,
        status_file: status,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/v2/search_file",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          // console.log(response);
          if (response.data.status === "OK") {
            console.log("filenomal", response);
            var i;
            for (i = 0; i < response.data.data.length; i++) {
              let datafolder = {};
              let typefile = response.data.data[i]["filename"].split(".");
              datafolder["account_reciever"] = "";
              datafolder["account_sender"] = "";
              datafolder["file_id"] = response.data.data[i]["id"];
              datafolder["file_name"] = response.data.data[i]["filename"];
              datafolder["file_size"] = response.data.data[i]["size_file"];
              datafolder["file_status"] = response.data.data[i]["status_file"];
              datafolder["file_owner_eng"] = response.data.data[i]["name_eng"];
              datafolder["file_owner_th"] = response.data.data[i]["name_th"];
              datafolder["file_linkshare"] = "-";
              datafolder["file_status_share"] =
                response.data.data[i]["status_share"];
              datafolder["file_status_sharelink"] = "";
              datafolder["file_permission"] = "";
              datafolder["file_type"] = typefile[typefile.length - 1];
              datafolder["file_date"] = "";
              datafolder["file_lastdtm"] = response.data.data[i]["last_dtm"];
              datafolder["file_department"] = "";
              datafolder["status_expired"] =
                response.data.data[i]["status_expired"];
              datafolder["expiration_time"] =
                response.data.data[i]["expiration_time"];
              datafolder["warning_expiration_time"] =
                response.data.data[i]["warning_expiration_time"];
              datafolder["file_icon"] = this.seticon(
                typefile[typefile.length - 1]
              );
              datafolder["permission_account"] =
                response.data.data[i].permission_account;
              datafolder["file_permission_2"] =
                response.data.result[i].permission_account_v2;
              datafolder["permission_department_setting"] =
                response.data.data[i].permission_department_setting;
              datafolder["permission_setting"] =
                response.data.data[i].permission_setting;
              this.rootfile.push(datafolder);
              this.buff.push(datafolder);
              this.filenomal.push(datafolder);
            }
            if (this.clickSort === true) {
              this.rootfile.push(this.sortFile(this.rootfile));
            }

            this.loaddataprogress = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    async loadfilesshare(folder, status) {
      this.filenomal = [];
      var payloadSh = {
        account_id: this.dataAccountId,
        status: "",
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        // .post(
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_sharefile",
        //   payloadSh
        // )
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE + "/api/taxid/get_sharefile",
          payloadSh,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("fileshare", response);

            var i;
            for (i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i]["status_file"] === "S") {
                let datafolder = {};
                let typefile = response.data.data[i]["filename"].split(".");
                datafolder["account_reciever"] =
                  response.data.data[i]["account_id_reciever"];
                datafolder["account_sender"] =
                  response.data.data[i]["account_id_sender"];
                datafolder["file_id"] = response.data.data[i]["id"];
                datafolder["file_name"] = response.data.data[i]["filename"];
                datafolder["file_size"] = response.data.data[i]["size_file"];
                datafolder["file_status"] =
                  response.data.data[i]["status_file"];
                datafolder["file_owner_eng"] = response.data.data[i].name_eng;
                datafolder["file_owner_th"] = response.data.data[i].name_th;
                datafolder["file_linkshare"] = "";
                datafolder["file_status_share"] = "Y";
                datafolder["file_status_sharelink"] = "";
                datafolder["file_permission"] = response.data.data[i]["status"];
                datafolder["file_type"] = typefile[typefile.length - 1];
                datafolder["file_department"] = "";
                datafolder["file_date"] = response.data.data[i]["share_dtm"];
                datafolder["file_lastdtm"] = "";
                datafolder["status_expired"] =
                  response.data.data[i]["status_expired"];
                datafolder["expiration_time"] =
                  response.data.data[i]["expiration_time"];
                datafolder["file_icon"] = this.seticon(
                  typefile[typefile.length - 1]
                );
                datafolder["permission_account"] =
                  response.data.data[i].permission_account;
                datafolder["file_permission_2"] =
                response.data.result[i].permission_account_v2;
                datafolder["permission_department_setting"] =
                  response.data.data[i].permission_department_setting;
                datafolder["permission_setting"] =
                  response.data.data[i].permission_setting;
                this.rootfile.push(datafolder);
                this.buff.push(datafolder);
                this.filenomal.push(datafolder);
              }
              if (this.clickSort === true) {
                this.rootfile.push(this.sortFile(this.rootfile));
              }
            }

            // this.loaddataprogress = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            // "ไม่สามารถเรียกไฟล์ได้ในขณะนี้"
            title: this.$t("toast.cannotopenfile"),
          });
        });
    },

    seticon(_parameter) {
      return gb_setIconFiles.iconExtensionFilesOnly_new(_parameter);
    },

    calculatesize(_sizefilebyte) {
      return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },

    formatdatetime(_datetime) {
      if (
        _datetime === "" ||
        _datetime === "-" ||
        _datetime === undefined ||
        _datetime === null
      ) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute 
          // +
          // ":" +
          // second
        );
      }
    },
    selectItemTable(item) {
      return this.selectColor(item, "table")
        ? "background-color:#D6EAF8;"
        : "opacity: unset;";
    },
    selectColor(e, type) {
      if (type === "table") {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      } else {
        if (this.arrayfileidselect.length < 1) {
        } else {
          if (this.arrayfileidselect.filter(item => item === e).length > 0) {
            return "color:#2471A3";
          }
        }
      }
    },
    setSelectitemtable(file) {
      console.log('file',file)
      if (file.file_id) {
        if (this.statusmutipledownload === false) {
          this.arrayfileidselect = [];
          if (file.status_lock_data === "N") {
            this.arrayfileidselect = [file.file_id]
          }
        }
      }
    },
  },
  watch: {
    size: function(val) {
      // this.loadfileandfolder();
    },
    sortAndDirection: {
      async handler(newValue, oldValue) {
        await new Promise((resolve) => {
          // console.log('sortAndDirection',newValue, oldValue);
          localStorage.setItem('sort_by', newValue.sort_by)
          localStorage.setItem('direction_by', newValue.direction)        
          const mapSortName = {
            type: "file_type",
            name: "file_name",
            last_dtm: "file_lastdtm",
            size_file: "file_size",
          }
          let _sortBy = mapSortName[newValue.sort_by]
          if (!_sortBy) {
            this.sortBy = []
            this.sortDesc = []
          } else {
            this.sortBy = [_sortBy]
            this.sortDesc = [newValue.direction === 'DESC']
          }
          // console.log('_sortBy',_sortBy)
          // console.log('sortDesc',this.sortDesc)
          resolve()
        });
        this.loadfileandfolder();
      },
      deep: true
    },
  },
  mounted() {
    var self = this;
    // self.loadfolder();
    // self.loadfoldershare();
    this.loadfileandfolder();
  },
  created() {},
};
</script>

<style>
/* #demo {
  width: 100%;
  height: 100%;
} */

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: visibility 175ms, opacity 175ms;
  display: table;
  text-shadow: 1px 1px 2px #000;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
  font: bold 42px Oswald, DejaVu Sans, Tahoma, sans-serif;
}

#dropzone {
  min-height: 100%;
}

#thiscontainer_starredfiles {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>
